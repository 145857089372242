<template>
    <component :is="homeConponent"></component>
</template>

<script>
export default {
    components: {
        HomeBht: () => import('./branch/bhtong/index/index.vue'),
        HomeFybh: () => import('./branch/fybh/index/index.vue'),
        HomeMancheng: () => import('./branch/mancheng/index/index.vue'),
        HomeYsbh: () => import('./branch/ysbh/index/index.vue'),
        HomeZceb: () => import('./branch/zceb/index/index.vue'),
    },
    asyncData({ req, store }) {
        /**
         * 根据不同来源，渲染不同首页
         * 默认使用bht首页
         */
        var homeConponent = 'HomeBht';

        if (store.state.webInfo && store.state.webInfo.webStyle) {
            homeConponent = store.state.webInfo.webStyle;
        }

        return {
            homeConponent,
        };
    },
};
</script>